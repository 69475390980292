import {
    Button,
    NoticeBar,
    Form,
    Field,
    CellGroup,
    Picker,
    Popup,
    Checkbox,
    Toast,
    Overlay,
    Loading,
    Dialog,
    Step,
    Steps,
    Uploader,
    Image as VanImage,
    ImagePreview,
    Tag
} from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

export function installVantUI(Vue) {
    Vue.use(Button)
        .use(NoticeBar)
        .use(Form)
        .use(Field)
        .use(CellGroup)
        .use(Picker)
        .use(Popup)
        .use(Checkbox)
        .use(Toast)
        .use(Overlay)
        .use(Loading)
        .use(Dialog)
        .use(Step)
        .use(Steps)
        .use(Uploader)
        .use(VanImage)
        .use(ImagePreview)
        .use(Tag)
}
