<template>
  <van-overlay :show="props.showFlag" @click="props.showFlag = false">
    <div class="wrapper" @click.stop>
      <van-loading size="24px" vertical>请稍后...</van-loading>
    </div>
  </van-overlay>
</template>

<script setup>
import {defineProps} from "vue";
const props = defineProps({
  showFlag: Boolean
})

</script>

<style scoped lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
