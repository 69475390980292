import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
        path: "/bixuan",
        name: "bixuan",
        meta: {title: "福利比选"},
        component: () => import("@/view/bixuan/index.vue"),
        children: [
            {path: 'entry', name: 'entry',meta: {title: "工会福利"}, component: () => import('@/view/bixuan/register-entry/index.vue')},
            {path: 'from', name: 'from', meta: {title: "福利登记"},component: () => import('@/view/bixuan/register-from/index.vue')},
            {path: 'list', name: 'list', meta: {title: "福利比选"},component: () => import('@/view/bixuan/register-list/index.vue')},
            {path: 'selection', name: 'selection', meta: {title: "福利比选"},component: () => import('@/view/bixuan/register-selection/index.vue')},
            {path: 'details', name: 'details', meta: {title: "福利比选"},component: () => import('@/view/bixuan/register-details/index.vue')},
            {path: 'rule', name: 'rule', meta: {title: "比选规则"},component: () => import('@/view/bixuan/register-rule/index.vue')},
            {path: 'invite', name: 'invite', meta: {title: "询比方案"},component: () => import('@/view/pc/register-invite/index.vue')}
        ]
    },
    {
        path: "/yct",
        name: "yct",
        meta: {title: "共享云餐厅"},
        component: () => import("@/view/yct/index.vue"),
        children: [
            {path: '', name: 'yct-entry',meta: {title: "共享云餐厅介绍"}, component: () => import('@/view/yct/entry/index.vue')}
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
